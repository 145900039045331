import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CreateCollectionReqDto,
  DisplayImageContent,
  GenerateCollectionIdentifierDto,
  GenerateCreateInstrumentDto,
  GenerateCreateSessionImageDto,
  GenerateImageDataDto,
  GenerateImageDto,
  GenerateImagesState,
  GenerateInstrumentDto,
  GenerateRemoveSessionImageDto,
  GenerateUpdateGridStateDto,
  GridPoint,
  ManipulateImageDto,
  MoveWithContentIdDto,
  GenerateCollectionSimpleDto,
  AddToCollectionRequest,
  CollectionReqDto,
  GenerateSyncSessionCoreDto,
} from './types';
import { EContentState, EReducerState } from '../../app/enum';
import { apiAxios } from '../../app/axios';
import { Content } from '../../app/types';
import axios from 'axios';
import { RootState } from '../../app/store';
import { EEvents } from '../../app/enum';
import Emitter from '../../app/emitter';
import { getNetworkType } from '../web3/network-utils';
import {
  ReturnSubmitNftDto,
  SubmitNftDto,
  Nft,
  UpdateContentNftName,
  UpdateContentNftState,
} from '../web3/types';
import { ENftState } from '../web3/enum';
import { DLinkList, DLinkNode } from 'd-link-list';
import { GenerateSessionDto } from './types/generate-session-dto';
import {
  EGenerateImageType,
  EMoveGridDirection,
  EGenerateSessionState,
  ECollectionType,
} from './enum';
import { generateDefaultInstrument } from './generate-default-instrument';
import { isMobile } from 'react-device-detect';
import { get, head } from 'lodash';

export const initialState: GenerateImagesState = {
  status: EReducerState.IDLE,
  sessionState: EGenerateSessionState.NONE,
  images: new DLinkList<DLinkList<DisplayImageContent>>(),
  observableTrigger: 0,
  sessionExistsStatus: EReducerState.IDLE,
  getSessionStatus: EReducerState.IDLE,
  getSessionCoreStatus: EReducerState.IDLE,
  pageLoadState: EReducerState.LOADING,
  gridState: [],
  collectionIdentifier: {},
  instrument: generateDefaultInstrument,
  loadingInstrument: false,
  queuedImagesToGenerate: [],
  createdCollectionsLoadState: EReducerState.IDLE,
  createdCollectionsImageDataState: EReducerState.IDLE,
  createdCollections: [],
  processingCollections: [],
};

export const selectPageLoadState = (state: RootState) =>
  state.generateImages.pageLoadState;
export const selectGenerateImages = (state: RootState) =>
  state.generateImages.images;
export const selecObservableTrigger = (state: RootState) =>
  state.generateImages.observableTrigger;
export const selectInstrument = (state: RootState) =>
  state.generateImages.instrument;
export const selectLoadingInstrument = (state: RootState) =>
  state.generateImages.loadingInstrument;
export const selectCreatedCollections = (state: RootState) =>
  state.generateImages.createdCollections;
export const selectCollectionIdentifier = (state: RootState) =>
  state.generateImages.collectionIdentifier;
export const selectProcessingCollections = (state: RootState) =>
  state.generateImages.processingCollections;
export const selectSessionState = (state: RootState) =>
  state.generateImages.sessionState;

export const generateImagesSlice = createSlice({
  name: 'generateImages',
  initialState,
  reducers: {
    resetGenerateState: (state) => {
      state.images = new DLinkList<DLinkList<DisplayImageContent>>();
      state.observableTrigger = 0;
      state.sessionExistsStatus = EReducerState.IDLE;
      state.getSessionStatus = EReducerState.IDLE;
      state.pageLoadState = EReducerState.LOADING;
      state.gridState = [];
      state.collectionIdentifier = {};
      state.instrument = generateDefaultInstrument;
      state.queuedImagesToGenerate = [];
      state.status = EReducerState.IDLE;
      state.sessionState = EGenerateSessionState.ACTIVE;
    },
    resetGenerateStateSignOut: (state) => {
      state.images = new DLinkList<DLinkList<DisplayImageContent>>();
      state.observableTrigger = 0;
      state.sessionExistsStatus = EReducerState.IDLE;
      state.getSessionStatus = EReducerState.IDLE;
      state.pageLoadState = EReducerState.LOADING;
      state.gridState = [];
      state.collectionIdentifier = {};
      state.instrument = generateDefaultInstrument;
      state.queuedImagesToGenerate = [];
      state.status = EReducerState.IDLE;
      state.createdCollectionsLoadState = EReducerState.IDLE;
      state.createdCollectionsImageDataState = EReducerState.IDLE;
      state.createdCollections = [];
      state.sessionState = EGenerateSessionState.NONE;
    },
    setSessionState: (state, action: PayloadAction<EGenerateSessionState>) => {
      state.sessionState = action.payload;
    },
    addImageNewColumn: (state, action: PayloadAction<DisplayImageContent>) => {
      const newList = new DLinkList<DisplayImageContent>();
      newList.push(action.payload);
      state.images.push(newList);
      state.observableTrigger++;
    },
    addImagesNewColumn: (
      state,
      action: PayloadAction<DisplayImageContent[]>,
    ) => {
      const newList = new DLinkList<DisplayImageContent>();
      const images = action.payload;
      images.forEach((image) => newList.push(image));
      state.images.push(newList);
      state.observableTrigger++;
    },
    addImage: (
      state,
      action: PayloadAction<{
        dList: DLinkList<DisplayImageContent>;
        image: DisplayImageContent;
      }>,
    ) => {
      const list = action.payload.dList;
      const displayList = state.images.getByValue(list)?.value;
      if (displayList) {
        displayList.push(action.payload.image);
        state.observableTrigger++;
      }
    },
    addImageToParentColumn: (
      state,
      action: PayloadAction<{ image: DisplayImageContent }>,
    ) => {
      //find parent column
      const parentId = action.payload.image.parentId;
      let parentColumn: DLinkList<DisplayImageContent> | undefined;
      for (let list of state.images.values()) {
        for (let image of list.values()) {
          if (image.content.id === parentId) {
            parentColumn = list;
            break;
          }
        }
        if (parentColumn) {
          break;
        }
      }
      if (!parentColumn) {
        throw new Error('addImageToParentColumn: parentColumn is undefined');
      }
      parentColumn.push(action.payload.image);
      state.observableTrigger++;
    },
    addReqToGenerateQueue: (state, action: PayloadAction<GenerateImageDto>) => {
      state.queuedImagesToGenerate.push(action.payload);
    },
    resetGenerateQueue: (state) => {
      state.queuedImagesToGenerate = [];
    },
    moveImageLeft: (state, action: PayloadAction<ManipulateImageDto>) => {
      //if image is in first column, and it's the only image, do nothing
      const imageDto: ManipulateImageDto = action.payload;
      if (
        imageDto.imageIndex === undefined ||
        imageDto.listIndex === undefined
      ) {
        throw new Error('moveImageLeft: imageIndex or listIndex is undefined');
      }
      if (imageDto.listIndex === 0 && imageDto.dList.length === 1) {
        // console.log(
        //   'moveImageLeft: image is in first column and is only image, do nothing',
        // );
        return;
      }
      //remove image from list
      const currentList = state.images.getByValue(imageDto.dList);
      if (!currentList?.value) {
        throw new Error('moveImageLeft: currentList is undefined');
      }
      const imageNode = currentList.value.removeValue(imageDto.image);
      if (!imageNode) {
        throw new Error('moveImageLeft: imageNode is undefined');
      }
      if (imageDto.listIndex === 0) {
        const newList = new DLinkList<DisplayImageContent>();
        newList.push(imageNode);
        state.images.addPrev(newList, currentList);
      } else {
        const prevList = currentList.prev;
        if (!prevList?.value) {
          throw new Error('moveImageLeft: prevList is undefined');
        }
        prevList.value.push(imageNode);
      }
      if (currentList.value.length === 0) {
        state.images.removeValue(currentList.value);
      }
      state.observableTrigger++;
    },
    moveImageRight: (state, action: PayloadAction<ManipulateImageDto>) => {
      const imageDto: ManipulateImageDto = action.payload;
      if (
        imageDto.imageIndex === undefined ||
        imageDto.listIndex === undefined
      ) {
        throw new Error('moveImageRight: imageIndex or listIndex is undefined');
      }
      if (
        imageDto.listIndex === state.images.length - 1 &&
        imageDto.dList.length === 1
      ) {
        // console.log(
        //   'moveImageRight: image is in last column and is only image, do nothing',
        // );
        return;
      }
      const currentList = state.images.getByValue(imageDto.dList);
      if (!currentList?.value) {
        throw new Error('moveImageRight: currentList is undefined');
      }
      const imageNode = currentList.value.removeValue(imageDto.image);
      if (!imageNode) {
        throw new Error('moveImageRight: imageNode is undefined');
      }

      if (imageDto.listIndex === state.images.length - 1) {
        const newList = new DLinkList<DisplayImageContent>();
        newList.push(imageNode);
        state.images.addNext(newList, currentList);
      } else {
        const nextList = currentList.next;
        if (!nextList?.value) {
          throw new Error('moveImageRight: nextList is undefined');
        }
        nextList.value.push(imageNode);
        if (currentList.value.length === 0) {
          state.images.removeValue(currentList.value);
        }
      }
      state.observableTrigger++;
    },
    moveImageUp: (state, action: PayloadAction<ManipulateImageDto>) => {
      const imageDto: ManipulateImageDto = action.payload;
      if (
        imageDto.imageIndex === undefined ||
        imageDto.listIndex === undefined
      ) {
        throw new Error('moveImageUp: imageIndex or listIndex is undefined');
      }
      if (imageDto.imageIndex === 0) {
        // console.log(
        //   'moveImageUp: image is already at top of column, do nothing',
        // );
        return;
      }
      const list = state.images.getByValue(imageDto.dList);
      if (!list?.value) {
        throw new Error('moveImageUp: list is undefined');
      }
      if (imageDto.imageIndex > list.value.length) {
        throw new Error('moveImageUp: imageIndex is greater than list length');
      }
      list.value.swapAtIndex(imageDto.imageIndex, imageDto.imageIndex - 1);
      state.observableTrigger++;
    },
    moveImageDown: (state, action: PayloadAction<ManipulateImageDto>) => {
      const imageDto: ManipulateImageDto = action.payload;
      if (
        imageDto.imageIndex === undefined ||
        imageDto.listIndex === undefined
      ) {
        throw new Error('moveImageDown: imageIndex or listIndex is undefined');
      }
      if (imageDto.imageIndex === imageDto.dList.length) {
        // console.log(
        //   'moveImageDown: image is already at bottom of column, do nothing',
        // );
        return;
      }
      const list = state.images.getByValue(imageDto.dList);
      if (!list?.value) {
        throw new Error('moveImageDown: list is undefined');
      }
      list?.value.swapAtIndex(imageDto.imageIndex, imageDto.imageIndex + 1);
      state.observableTrigger++;
    },
    updateImageData: (state, action: PayloadAction<GenerateImageDataDto>) => {
      const imageData: GenerateImageDataDto = action.payload;
      let updated = false;
      for (let list of state.images.values()) {
        for (let image of list.values()) {
          if (image.content.id === imageData.contentId) {
            image.imageData = imageData.imageData;
            updated = true;
            break;
          }
        }
        if (updated) {
          state.observableTrigger++;
          break;
        }
      }
    },
    setPageLoadState: (state, action: PayloadAction<EReducerState>) => {
      state.pageLoadState = action.payload;
    },
    setGridState: (state, action: PayloadAction<string[][]>) => {
      state.gridState = action.payload;
    },
    setCollectionIdentifier: (
      state,
      action: PayloadAction<GenerateCollectionIdentifierDto>,
    ) => {
      state.collectionIdentifier = action.payload;
    },
    setCreatedCollections: (
      state,
      action: PayloadAction<GenerateCollectionSimpleDto[]>,
    ) => {
      //iterate and compare, only update
      if (!state.createdCollections) {
        state.createdCollections = action.payload;
        state.observableTrigger++;
        return;
      }
      //filter out removed collections
      state.createdCollections = state.createdCollections.filter((c) =>
        action.payload.some(
          (collection) => collection.collectionId === c.collectionId,
        ),
      );
      //add any new collections
      const newCollections: GenerateCollectionSimpleDto[] = [];
      for (let collection of action.payload) {
        let found = false;
        for (let i = 0; i < state.createdCollections.length; i++) {
          if (
            state.createdCollections[i].collectionId === collection.collectionId
          ) {
            if (
              !state.createdCollections[i].imageData &&
              collection.imageData
            ) {
              state.createdCollections[i].imageData = collection.imageData;
            }
            found = true;
            break;
          }
        }
        if (!found) {
          newCollections.push(collection);
        }
      }
      state.createdCollections.push(...newCollections);
      state.createdCollections.sort((a, b) => {
        const nameA = a.name.replace(/[^a-zA-Z0-9 ]/g, '');
        const nameB = b.name.replace(/[^a-zA-Z0-9 ]/g, '');
        return nameA.localeCompare(nameB);
      });
    },
    updateProcessingCollections: (
      state,
      action: PayloadAction<{ collectionId: string; add: boolean }>,
    ) => {
      const { collectionId, add } = action.payload;
      if (add) {
        state.processingCollections.push(collectionId);
      } else {
        state.processingCollections = state.processingCollections.filter(
          (id) => id !== collectionId,
        );
      }
    },
    updateGridState: (state) => {
      //iterate through images and save contentIds in gridState
      state.gridState = [];
      state.images.map((list, i) => {
        list.map((image, j) => {
          if (state.gridState.length <= i) {
            state.gridState.push([]);
          }
          state.gridState[i][j] = image.content.id;
          return null;
        });
        return null;
      });
    },
    constructGrid: (
      state,
      action: PayloadAction<{
        dList: DLinkList<DLinkList<DisplayImageContent>>;
      }>,
    ) => {
      //TODO -- basically all the logic in generateSessionImagesAsync should be here...

      state.images = action.payload.dList;
      state.observableTrigger++;
    },
    forkImageNextColumn: (
      state,
      action: PayloadAction<{
        dList: DLinkList<DisplayImageContent>;
        image: DisplayImageContent;
      }>,
    ) => {
      const list = action.payload.dList;
      let displayNode = state.images.getByValue(list);
      let displayList = displayNode?.value;

      if (displayList && displayNode) {
        const image = action.payload.image;
        const removeImage = displayList.removeValue(image);
        if (removeImage) {
          const newList = new DLinkList<DisplayImageContent>();
          if (removeImage.content.nft?.state === ENftState.MINT_REQUESTED) {
            removeImage.content.nft.state = ENftState.WAITING_APPROVAL;
          }
          newList.push(removeImage);
          state.images.addNext(newList, displayNode);
          state.observableTrigger++;
        }
      }
    },
    setInstrument: (state, action: PayloadAction<GenerateInstrumentDto>) => {
      state.instrument = action.payload;
    },
    setLoadingInstrument: (state, action: PayloadAction<boolean>) => {
      state.loadingInstrument = action.payload;
    },
    removeImage: (
      state,
      action: PayloadAction<{
        dList: DLinkList<DisplayImageContent>;
        image: DisplayImageContent;
      }>,
    ) => {
      const list = action.payload.dList;
      const displayList = state.images.getByValue(list)?.value;
      if (displayList) {
        displayList.removeValue(action.payload.image);
        if (displayList.length === 0) {
          state.images.removeValue(displayList);
        }
        state.observableTrigger++;
      }
    },
    validateContentNft: (
      state,
      action: PayloadAction<{
        dList: DLinkList<DisplayImageContent>;
        image: DisplayImageContent;
      }>,
    ) => {
      const list = action.payload.dList;
      const displayList = state.images.getByValue(list)?.value;
      if (displayList) {
        const image = action.payload.image;
        const node = displayList.getByValue(image);
        if (node) {
          const content = node.value.content;
          if (content.nft === undefined) {
            const nft: Nft = {};
            content.nft = nft;
            state.observableTrigger++;
          }
        }
      }
    },
    updateContentNftName: (
      state,
      action: PayloadAction<{
        dList: DLinkList<DisplayImageContent>;
        image: DisplayImageContent;
        updateName: UpdateContentNftName;
      }>,
    ) => {
      const list = action.payload.dList;
      const displayList = state.images.getByValue(list)?.value;
      if (displayList) {
        const image = action.payload.image;
        const node = displayList.getByValue(image);
        if (node) {
          const content = node.value.content;
          if (content.nft === undefined) {
            throw new Error(
              'updateContentNftName: nft is undefined for content: ' +
                content.id,
            );
          } else {
            content.nft!.name = action.payload.updateName.name;
            state.observableTrigger++;
          }
        }
      }
    },
    updateContentNftState: (
      state,
      action: PayloadAction<{
        dList: DLinkList<DisplayImageContent>;
        image: DisplayImageContent;
        updateNft: UpdateContentNftState;
      }>,
    ) => {
      const list = action.payload.dList;
      const displayList = state.images.getByValue(list)?.value;
      if (displayList) {
        const image = action.payload.image;
        const node = displayList.getByValue(image);
        if (node) {
          const content = node.value.content;
          if (content.nft === undefined) {
            throw new Error(
              'updateContentNftState: nft is undefined for content: ' +
                content.id,
            );
          } else {
            content.nft!.state = action.payload.updateNft.state;
            state.observableTrigger++;
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncGenerateDoesSessionExistAsync.pending, (state) => {
        state.sessionExistsStatus = EReducerState.LOADING;
      })
      .addCase(syncGenerateDoesSessionExistAsync.fulfilled, (state, action) => {
        state.sessionExistsStatus = EReducerState.IDLE;
      })
      .addCase(syncGenerateDoesSessionExistAsync.rejected, (state, action) => {
        state.sessionExistsStatus = EReducerState.IDLE;
        console.error(
          'syncGenerateDoesSessionExistAsync.rejected: ' + action.error.message,
        );
      })
      .addCase(syncGenerateSessionAsync.pending, (state) => {
        // console.log('syncGenerateSessionAsync.pending');
        state.getSessionStatus = EReducerState.LOADING;
      })
      .addCase(syncGenerateSessionAsync.fulfilled, (state, action) => {
        // console.log('syncGenerateSessionAsync.fulfilled');
        state.getSessionStatus = EReducerState.IDLE;
      })
      .addCase(syncGenerateSessionAsync.rejected, (state, action) => {
        console.error(
          'syncGenerateSessionAsync.rejected: ' + action.error.message,
        );
        state.getSessionStatus = EReducerState.FAILED;
      })
      .addCase(syncSessionCoreAsync.pending, (state) => {
        state.getSessionCoreStatus = EReducerState.LOADING;
      })
      .addCase(syncSessionCoreAsync.fulfilled, (state) => {
        state.getSessionCoreStatus = EReducerState.IDLE;
      })
      .addCase(syncSessionCoreAsync.rejected, (state, action) => {
        console.error('syncSessionCoreAsync.rejected: ' + action.error.message);
        state.getSessionCoreStatus = EReducerState.FAILED;
      })
      .addCase(updateGridStateAsync.rejected, (state, action) => {
        console.error('updateGridStateAsync.rejected: ' + action.error);
      })
      .addCase(updateInstrumentStateAsync.rejected, (state, action) => {
        console.error('updateInstrumentStateAsync.rejected: ' + action.error);
      })
      .addCase(removeImageAsync.rejected, (state, action) => {
        console.error('removeImageAsync.rejected: ' + action.error.message);
      })
      .addCase(
        moveImageAsync.fulfilled,
        (state, action: PayloadAction<GridPoint>) => {
          Emitter.emit(EEvents.FOCUS_ON_GRID_IMAGE, action.payload);
        },
      )
      .addCase(moveImageAsync.rejected, (state, action) => {
        console.error('moveImageAsync.rejected: ' + action.error.message);
      })
      .addCase(createCollectionGenerateNameAsync.rejected, (state, action) => {
        console.error(
          'createCollectionGenerateNameAsync.rejected: ' + action.error,
        );
      })
      .addCase(generateSessionImagesAsync.pending, (state) => {
        state.status = EReducerState.LOADING;
        Emitter.emit(EEvents.INCREMENT_SUMMON_COUNT, null);
      })
      .addCase(generateSessionImagesAsync.fulfilled, (state, action) => {
        state.status = EReducerState.IDLE;
        if (
          state.sessionState !== EGenerateSessionState.INITIALIZING_COLLECTION
        ) {
          Emitter.emit(EEvents.DECREMENT_SUMMON_COUNT, null);
        }
      })
      .addCase(generateSessionImagesAsync.rejected, (state, action) => {
        state.status = EReducerState.FAILED;
        console.error('generateSessionImagesAsync.rejected: ' + action.error);
        Emitter.emit(EEvents.DECREMENT_SUMMON_COUNT, null);
      })
      .addCase(generateImageQueueAsync.rejected, (state, action) => {
        console.error('generateImageQueueAsync.rejected: ' + action.error);
      })
      .addCase(getCollectionsSimpleAsync.pending, (state) => {
        state.createdCollectionsLoadState = EReducerState.LOADING;
      })
      .addCase(getCollectionsSimpleAsync.fulfilled, (state) => {
        state.createdCollectionsLoadState = EReducerState.IDLE;
      })
      .addCase(getCollectionsSimpleAsync.rejected, (state, action) => {
        state.createdCollectionsLoadState = EReducerState.FAILED;
        console.error(
          'getCollectionsSimpleAsync.rejected: ' + action.error.message,
        );
      })
      .addCase(updateCollectionsImageDataAsync.pending, (state) => {
        state.createdCollectionsImageDataState = EReducerState.LOADING;
      })
      .addCase(updateCollectionsImageDataAsync.fulfilled, (state) => {
        state.createdCollectionsImageDataState = EReducerState.IDLE;
      })
      .addCase(updateCollectionsImageDataAsync.rejected, (state, action) => {
        state.createdCollectionsImageDataState = EReducerState.FAILED;
        console.error(
          'updateCollectionsImageDataAsync.rejected: ' + action.error.message,
        );
      })
      .addCase(addImagesToCollectionAsync.rejected, (state, action) => {
        console.error(
          'addImagesToCollectionAsync.rejected: ' + action.error.message,
        );
      })
      .addCase(
        submitGeneratedImageAsync.fulfilled,
        (state, action: PayloadAction<ReturnSubmitNftDto>) => {
          // console.log(`submitGenerateImageAsync.fulfilled: ${action.payload}`);
        },
      )
      .addCase(submitGeneratedImageAsync.rejected, (state, action) => {
        const { message, dList, image } = action.payload as {
          message: string;
          dList: DLinkList<DisplayImageContent>;
          image: DisplayImageContent;
        };
        console.error('submitGeneratedImageAsync.rejected: ' + message);
        const displayList = state.images.getByValue(dList)?.value;
        if (displayList) {
          const node = displayList.getByValue(image);
          if (node) {
            const content = node.value.content;
            if (content.nft) {
              if (content.nft.state === ENftState.MINT_REQUESTED) {
                content.nft.state = ENftState.NONE;
                state.observableTrigger++;
              }
            }
          }
        }
      });
  },
});
export const {
  addImageNewColumn,
  addImagesNewColumn,
  addImage,
  addImageToParentColumn,
  addReqToGenerateQueue,
  resetGenerateQueue,
  updateImageData,
  removeImage,
  validateContentNft,
  updateContentNftName,
  updateContentNftState,
  forkImageNextColumn,
  updateGridState,
  setGridState,
  constructGrid,
  setCollectionIdentifier,
  updateProcessingCollections,
  setInstrument,
  setLoadingInstrument,
  setPageLoadState,
  resetGenerateState,
  resetGenerateStateSignOut,
  moveImageLeft,
  moveImageRight,
  moveImageUp,
  moveImageDown,
  setCreatedCollections,
  setSessionState,
} = generateImagesSlice.actions;

export const syncGenerateDoesSessionExistAsync = createAsyncThunk(
  'generateImages/syncGenerateDoesSessionExistAsync',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiAxios.post(
        '/session/generate/does-session-exist',
      );
      const doesSessionExist: boolean = response.data.exists;
      if (!doesSessionExist) {
        dispatch(setSessionState(EGenerateSessionState.BLANK_SLATE));
      } else {
        dispatch(setSessionState(EGenerateSessionState.CHOOSING));
      }
      return doesSessionExist;
    } catch (err) {
      const message = (err as Error).message;
      throw rejectWithValue(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      if (state.sessionExistsStatus === EReducerState.LOADING) {
        return false;
      }
    },
  },
);

export const syncGenerateSessionAsync = createAsyncThunk(
  'generateImages/getGenerateSessionAsync',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const { generateImages: initialState } = getState() as {
        generateImages: GenerateImagesState;
      };
      if (initialState.sessionState === EGenerateSessionState.BLANK_SLATE) {
        await dispatch(getCollectionsSimpleAsync()).unwrap();
        dispatch(updateCollectionsImageDataAsync());
        dispatch(setPageLoadState(EReducerState.IDLE));
        return;
      }

      const response = await apiAxios.get(
        '/session/generate/get-active-session',
      );

      const session: GenerateSessionDto = response.data;
      if (!session.collection) {
        dispatch(setPageLoadState(EReducerState.IDLE));
        return;
      }
      if (
        !session.collection.serializedGridState &&
        !session.collection.contentIds
      ) {
        dispatch(setPageLoadState(EReducerState.IDLE));
        return;
      }
      if (session.collection.content) {
        let hasGeneratedContent = false;
        for (let content of session.collection.content) {
          if (content.contentState === EContentState.GENERATED) {
            hasGeneratedContent = true;
            break;
          }
        }
        if (!hasGeneratedContent) {
          dispatch(setPageLoadState(EReducerState.IDLE));
          return;
        }
      }

      const syncCoreDto: GenerateSyncSessionCoreDto = {
        session: session,
      };
      await dispatch(syncSessionCoreAsync(syncCoreDto));

      let gridState: string[][] = [];
      if (session.collection.serializedGridState) {
        const serializedGridState: string =
          session.collection.serializedGridState;
        gridState = JSON.parse(serializedGridState);
        dispatch(setGridState(gridState));
      }
      let collectionIds = session.collection.contentIds;
      //remove any contenteIds of content that is in requested state
      const filteredIds: string[] = [];
      for (let i = 0; i < session.collection.content.length; i++) {
        if (
          session.collection.content[i].contentState === EContentState.GENERATED
        ) {
          filteredIds.push(session.collection.content[i].id);
        }
      }
      collectionIds = filteredIds;

      //get all content for collectionIds that are not in images
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      //NOTE FROM THIS POINT FORWARD WE ARE LEAVING A GAP FOR SOMEONE ELSE MODIFIYING IMAGES TO DISRUPT STATE BY NOT DOING IT IN REDUCER,
      //NEED TO HANDLE IT WITH STATE OR SOMETHING LATER, BUT FOR NOW THIS WILL BE STATE OF TRUTH!
      let fetchedIds: string[] = [];
      for (let list of state.images) {
        for (let image of list) {
          fetchedIds.push(image.content.id);
        }
      }
      //make sure colletionIds and gridState contain the same values
      //remove any values from gridState that are not in collectionIds
      const filteredState = gridState.map((column) =>
        column.filter((id) => collectionIds.includes(id)),
      );
      //remove any empty columns
      gridState = filteredState.filter((column) => column.length > 0);

      let flattendGridState: string[] = [];
      for (let i = 0; i < gridState.length; i++) {
        for (let j = 0; j < gridState[i].length; j++) {
          flattendGridState.push(gridState[i][j]);
        }
      }
      //find out if collectionIds has any values that flattenedGridState does not
      const missingIds: string[] = [];
      for (let i = 0; i < collectionIds.length; i++) {
        if (!flattendGridState.includes(collectionIds[i])) {
          missingIds.push(collectionIds[i]);
          flattendGridState.push(collectionIds[i]);
        }
      }
      //add missing ids as column to gridstate
      if (missingIds.length > 0) {
        gridState.push(missingIds);
      }

      //fetch content for any contentIds that are not in fetchedIds
      const fetchIds: string[] = [];
      for (let i = 0; i < collectionIds.length; i++) {
        if (!fetchedIds.includes(collectionIds[i])) {
          fetchIds.push(collectionIds[i]);
        }
      }

      let newPlaceholderImages: DLinkList<DisplayImageContent> =
        new DLinkList<DisplayImageContent>();

      const imageHolderAsync = session.collection.content.map(
        async (content) => {
          if (!fetchIds.includes(content.id)) {
            return null;
          }
          if (content.contentState !== EContentState.GENERATED) {
            // console.log('content not generated: ', content.id);
            return null;
          }
          const imageContent: DisplayImageContent = {
            content: content,
            imageData: null,
            colNum: 1,
            parentId: 'root',
          };
          newPlaceholderImages.push(imageContent);
          return;
        },
      );

      dispatch(getCollectionsSimpleAsync());

      await Promise.all(imageHolderAsync);

      state.images.push(newPlaceholderImages);
      const flattendGridImages: DLinkList<DisplayImageContent> =
        new DLinkList<DisplayImageContent>();
      //flatten state.images into a single list
      for (let list of state.images.values()) {
        while (list.length > 0) {
          flattendGridImages.push(list.shift()!);
        }
      }

      if (flattendGridImages.length !== flattendGridState.length) {
        throw rejectWithValue(
          'syncGenerateSessionAsync: flattendGridImages.length !== flattnedGridState.length',
        );
      }
      if (flattendGridImages.length === 0) {
        return session;
      }
      //sort flattened images in to same order as flattened gridState
      //omg there has to be a way to make this more effecient...
      for (let i = 0; i < flattendGridState.length; i++) {
        const id = flattendGridState[i];

        let swapNode: DLinkNode<DisplayImageContent> | null = null;
        let node: DLinkNode<DisplayImageContent> =
          flattendGridImages.headNode()!;
        for (let j = 0; j < flattendGridImages.length; j++) {
          if (node.value.content.id === id) {
            swapNode = node;
            break;
          }
          if (!node.next) {
            throw rejectWithValue(
              'reached end of flattend grid withuot finding id: ' +
                id +
                ' in syncGenerateSessionAsync',
            );
          }
          node = node.next;
        }
        if (swapNode === null) {
          throw rejectWithValue('swapNode is null in syncGenerateSessionAsync');
        }

        flattendGridImages.swapNodeAtIndex(swapNode, i);
      }

      //construct grid
      const newGrid: DLinkList<DLinkList<DisplayImageContent>> = new DLinkList<
        DLinkList<DisplayImageContent>
      >();
      let columnIndex: number = 0;
      while (flattendGridImages.length > 0) {
        const column = new DLinkList<DisplayImageContent>();
        for (let i = 0; i < gridState[columnIndex].length; i++) {
          column.push(flattendGridImages.shift()!);
        }
        newGrid.push(column);
        columnIndex++;
      }

      dispatch(setPageLoadState(EReducerState.IDLE));
      dispatch(constructGrid({ dList: newGrid }));
      dispatch(updateGridStateAsync());

      //get content we want to render, and sort it based on grid state
      const contentToFetch: Content[] = session.collection.content
        .filter((content) => {
          return (
            fetchIds.includes(content.id) &&
            content.contentState === EContentState.GENERATED
          );
        })
        .sort((a, b) => {
          const indexA = flattendGridState.indexOf(a.id);
          const indexB = flattendGridState.indexOf(b.id);
          if (indexA < indexB) {
            return -1;
          }
          if (indexA > indexB) {
            return 1;
          }
          return 0;
        });

      // Create a queue of promises
      const promiseQueue = [];

      // Function to process a content fetch
      async function processContent(content: Content) {
        if (!fetchIds.includes(content.id)) {
          return null;
        }
        if (content.contentState !== EContentState.GENERATED) {
          // console.log('content not generated: ', content.id);
          return null;
        }

        const url = content.thumbnailFullUrl || content.contentUrl;
        const imageResponse = await axios.get(url!, {
          responseType: 'blob',
        });
        const blob = new Blob([imageResponse.data], { type: 'image/png' });
        const dataUrl: string = URL.createObjectURL(blob);
        const imageDataDto: GenerateImageDataDto = {
          contentId: content.id,
          imageData: dataUrl,
        };
        dispatch(updateImageData(imageDataDto));
      }

      //start with 5 on mobile
      const chunkSize = isMobile ? 5 : contentToFetch.length;

      // Start the first chunkSize promises
      for (let i = 0; i < chunkSize; i++) {
        const content = contentToFetch.shift();
        if (content) {
          promiseQueue.push(processContent(content));
        }
      }

      // As each promise resolves, start a new one
      while (contentToFetch.length > 0) {
        await Promise.race(promiseQueue);
        const index = promiseQueue.findIndex((p) =>
          Promise.resolve(p)
            .then(() => true)
            .catch(() => false),
        );
        const content = contentToFetch.shift();
        if (content) {
          promiseQueue[index] = processContent(content);
        }
      }

      dispatch(updateCollectionsImageDataAsync());

      return session;
    } catch (err: unknown) {
      throw rejectWithValue(err as Error);
    }
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      if (state.getSessionStatus === EReducerState.LOADING) {
        // console.log('syncGenerateSessionAsync: already loading');
        return false;
      }
    },
  },
);

export const syncSessionCoreAsync = createAsyncThunk(
  'generateImages/syncSessionCoreAsync',
  async (
    syncDto: GenerateSyncSessionCoreDto,
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      let session: GenerateSessionDto;
      if (!syncDto.session) {
        const response = await apiAxios.get(
          '/session/generate/get-active-session',
        );
        session = response.data;
      } else {
        session = syncDto.session;
      }

      const collectionIdentifier: GenerateCollectionIdentifierDto = {
        id: session.collection.collectionId,
        name: session.collection.name,
      };
      dispatch(setCollectionIdentifier(collectionIdentifier));

      //set instrument
      if (session.instrument) {
        dispatch(
          setInstrument({
            id: session.instrument.instrumentId,
            name: session.instrument.name,
            instrumentInfo: session.instrument.instrument,
          }),
        );
      }
      dispatch(setLoadingInstrument(false));
    } catch (err: unknown) {
      throw rejectWithValue(err as Error);
    }
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      if (state.getSessionCoreStatus === EReducerState.LOADING) {
        // console.log('syncSessionCoreAsync: already loading');
        return false;
      }
    },
  },
);

export const generateSessionImagesAsync = createAsyncThunk(
  'generateImages/generateSessionImagesAsync',
  async (
    sessionImageDto: GenerateImageDto,
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      if (
        state.sessionState === EGenerateSessionState.INITIALIZING_COLLECTION
      ) {
        dispatch(addReqToGenerateQueue(sessionImageDto));
        return;
      }
      switch (sessionImageDto.type) {
        case EGenerateImageType.INSTRUMENT:
          if (
            !sessionImageDto.colNum ||
            !sessionImageDto.prompt ||
            !sessionImageDto.dList ||
            !sessionImageDto.systemContext
          ) {
            throw rejectWithValue(
              'generateSessionImagesAsync: undefined parameter for content: ' +
                sessionImageDto.type,
            );
          }
          break;
        case EGenerateImageType.UPCYCLE:
          if (
            !sessionImageDto.colNum ||
            !sessionImageDto.prompt ||
            !sessionImageDto.dList
          ) {
            throw rejectWithValue(
              'generateSessionImagesAsync: undefined parameter for content: ' +
                sessionImageDto.type,
            );
          }
          break;
        case EGenerateImageType.SUMMON:
          if (!sessionImageDto.prompt) {
            throw rejectWithValue(
              'generateSessionImagesAsync: prompt is undefined for content: ' +
                sessionImageDto.type,
            );
          }
          break;
        case EGenerateImageType.MIMIC:
          if (!sessionImageDto.parentId || !sessionImageDto.colNum) {
            throw rejectWithValue(
              'generateSessionImagesAsync: parentId is undefined for content: ' +
                sessionImageDto.type,
            );
          }
          break;
        case EGenerateImageType.UPLOAD:
          if (!sessionImageDto.imageBase64) {
            throw rejectWithValue(
              'generateSessionImagesAsync: keywords or imageBase64 is undefined for content: ' +
                sessionImageDto.type,
            );
          }
          break;
        case EGenerateImageType.SMOOSH:
          if (
            !sessionImageDto.smooshPromptParentId ||
            !sessionImageDto.smooshImageParentId
          ) {
            throw rejectWithValue(
              'generateSessionImagesAsync: smooshPromptParentId or smooshImageParentId is undefined for content: ' +
                sessionImageDto.type,
            );
          }
          break;
        case EGenerateImageType.PAINT:
          if (
            !sessionImageDto.prompt ||
            !sessionImageDto.maskBase64 ||
            !sessionImageDto.parentId
          ) {
            throw rejectWithValue(
              'generateSessionImagesAsync: prompt or maskBase64 is undefined for content: ' +
                sessionImageDto.type,
            );
          }
          break;
        default:
          throw rejectWithValue(
            'generateSessionImagesAsync: invalid content type: ' +
              sessionImageDto.type,
          );
      }

      const dto: GenerateCreateSessionImageDto = {
        type: sessionImageDto.type,
        prompt: sessionImageDto.prompt,
        systemContext: sessionImageDto.systemContext,
        parentId: sessionImageDto.parentId,
        keywords: sessionImageDto.keywords,
        imageBase64: sessionImageDto.imageBase64,
        maskBase64: sessionImageDto.maskBase64,
        smooshPromptParentId: sessionImageDto.smooshPromptParentId,
        smooshImageParentId: sessionImageDto.smooshImageParentId,
        state: state.sessionState,
        saiImageShape: sessionImageDto.imageShape,
        collectionType: ECollectionType.EXPLORER,
        parentInfluence: sessionImageDto.parentInfluence,
        genPlatform: sessionImageDto.genPlatform,
      };

      if (state.sessionState === EGenerateSessionState.BLANK_SLATE) {
        dispatch(
          setSessionState(EGenerateSessionState.INITIALIZING_COLLECTION),
        );
      }

      const response = await apiAxios.post('/session/create-image', dto);
      const content: Content | Content[] =
        response.data.length > 1 ? response.data : head(response.data)!;

      const getDataUrl = async (contentUrl: string): Promise<string> => {
        const imageResponse = await axios.get(contentUrl, {
          responseType: 'blob',
        });
        const blob = new Blob([imageResponse.data], { type: 'image/png' });
        const dataUrl: string = URL.createObjectURL(blob);
        return dataUrl;
      };

      switch (sessionImageDto.type) {
        case EGenerateImageType.INSTRUMENT:
        case EGenerateImageType.UPCYCLE:
        case EGenerateImageType.MIMIC:
          const imageContent: DisplayImageContent = {
            content: content as Content,
            imageData: await getDataUrl((content as Content).contentUrl!),
            colNum: sessionImageDto.colNum!,
            parentId: sessionImageDto.parentId!,
          };
          dispatch(
            addImage({ dList: sessionImageDto.dList!, image: imageContent }),
          );
          break;
        case EGenerateImageType.SUMMON:
        case EGenerateImageType.SMOOSH:
          const parentId = get(content, 'parentId', 'root');
          if (parentId !== 'root') {
            const imageContent: DisplayImageContent = {
              content: content as Content,
              imageData: await getDataUrl((content as Content).contentUrl!),
              colNum: sessionImageDto.colNum!,
              parentId,
            };
            dispatch(
              addImage({ dList: sessionImageDto.dList!, image: imageContent }),
            );
          } else {
            const newColumnImageContent: DisplayImageContent = {
              content: content as Content,
              imageData: await getDataUrl((content as Content).contentUrl!),
              colNum: 1,
              parentId,
            };
            dispatch(addImageNewColumn(newColumnImageContent));
          }
          break;
        case EGenerateImageType.UPLOAD:
          try {
            const newContent: DisplayImageContent[] = await Promise.all(
              (content as Content[]).map(async (c: Content) => {
                return {
                  content: c,
                  imageData: await getDataUrl(c.contentUrl!),
                  colNum: 1,
                  parentId: c.parentId || 'root',
                };
              }),
            );
            dispatch(addImagesNewColumn(newContent.reverse()));
          } catch (err) {
            console.error(err);
          }
          break;
        case EGenerateImageType.PAINT:
          const newImageContent: DisplayImageContent = {
            content: content as Content,
            imageData: await getDataUrl((content as Content).contentUrl!),
            colNum: sessionImageDto.colNum!,
            parentId: sessionImageDto.parentId!,
          };
          dispatch(addImageToParentColumn({ image: newImageContent }));
          break;
        default:
          throw rejectWithValue(
            'generateSessionImagesAsync: invalid content type: ' +
              sessionImageDto.type,
          );
      }

      const { generateImages: finalState } = getState() as {
        generateImages: GenerateImagesState;
      };
      //session gets created on first image so we need to update session state
      if (
        finalState.sessionState ===
        EGenerateSessionState.INITIALIZING_COLLECTION
      ) {
        dispatch(setSessionState(EGenerateSessionState.ACTIVE));
        dispatch(generateImageQueueAsync());
        await dispatch(syncSessionCoreAsync({ session: undefined }));
        await dispatch(updateGridStateAsync());
        return;
      }
      //update gridState
      dispatch(updateGridStateAsync());
    } catch (err: unknown) {
      const message = (err as Error).message;
      throw rejectWithValue(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      if (state.pageLoadState === EReducerState.LOADING) {
        // console.log(
        //   'generateSessionImageAsync: can not submit, still loading session',
        // );
        return false;
      }
      if (state.getSessionStatus === EReducerState.FAILED) {
        // console.log(
        //   'generateSessionImageAync: can not submit, session failed to load',
        // );
        return false;
      }
    },
  },
);

export const generateImageQueueAsync = createAsyncThunk(
  'generateImages/generateImageQueueAsync',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      const imageQueue: GenerateImageDto[] = state.queuedImagesToGenerate;
      const generatePromise = imageQueue.map(async (imageDto) => {
        //generateSesionImages will restart summon notification
        Emitter.emit(EEvents.DECREMENT_SUMMON_COUNT, null);
        dispatch(generateSessionImagesAsync(imageDto));
      });
      await Promise.all(generatePromise);
      dispatch(resetGenerateQueue());
    } catch (err: unknown) {
      const message = (err as Error).message;
      throw rejectWithValue(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      if (state.pageLoadState === EReducerState.LOADING) {
        // console.log(
        //   'generateImageQueueAsync: can not submit, still loading session',
        // );
        return false;
      }
      if (state.getSessionStatus === EReducerState.FAILED) {
        // console.log(
        //   'generateImageQueueAsync: can not submit, session failed to load',
        // );
        return false;
      }
      if (
        state.sessionState === EGenerateSessionState.INITIALIZING_COLLECTION
      ) {
        // console.log(
        //   'generateImageQueueAsync: can not submit, session is initializing collection',
        // );
        return false;
      }
    },
  },
);

export const createCollectionGenerateNameAsync = createAsyncThunk(
  'generateImages/createCollectionGenerateNameAsync',
  async (
    {
      image,
      additionalImageIds,
    }: {
      image: DisplayImageContent;
      additionalImageIds: string[] | undefined;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      dispatch(
        updateProcessingCollections({ collectionId: 'create', add: true }),
      );
      const createDto: CreateCollectionReqDto = {
        crestContentId: image.content.id,
        prompt: image.content.prompt,
      };
      const response = await apiAxios.post(
        '/collection/create-collection-generate-name',
        createDto,
      );

      if (additionalImageIds) {
        const addImagesDto: AddToCollectionRequest = {
          collectionId: response.data.collectionId,
          contentIds: additionalImageIds,
          type: ECollectionType.EXPLORER,
        };
        await dispatch(addImagesToCollectionAsync(addImagesDto));
      }

      await dispatch(getCollectionsSimpleAsync()).unwrap();
      dispatch(updateCollectionsImageDataAsync());
      dispatch(
        updateProcessingCollections({ collectionId: 'create', add: false }),
      );
    } catch (err: unknown) {
      const message = (err as Error).message;
      throw rejectWithValue(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      if (state.pageLoadState === EReducerState.LOADING) {
        // console.log(
        //   'createCollectionGenerateNameAsync: can not submit, still loading session',
        // );
        return false;
      }
      if (state.getSessionStatus === EReducerState.FAILED) {
        // console.log(
        //   'createCollectionGenerateNameAsync: can not submit, session failed to load',
        // );
        return false;
      }
    },
  },
);

export const getCollectionsSimpleAsync = createAsyncThunk(
  'generateImages/getCollectionsSimpleAsync',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await apiAxios.post(
        '/collection/get-collections-explorer-simple',
      );
      const collections: GenerateCollectionSimpleDto[] = response.data;
      dispatch(setCreatedCollections(collections));
      return collections;
    } catch (err: unknown) {
      const message = (err as Error).message;
      throw rejectWithValue(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };

      if (state.createdCollectionsLoadState === EReducerState.LOADING) {
        // console.log('getCollectionsSimpleAsync: already loading');
        return false;
      }
      if (state.getSessionStatus === EReducerState.FAILED) {
        // console.log(
        //   'getCollectionsSimpleAsync: can not submit, session failed to load',
        // );
        return false;
      }
    },
  },
);

export const deleteCollectionAsync = createAsyncThunk(
  'generateImages/deleteCollectionAsync',
  async (collectionId: string, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(updateProcessingCollections({ collectionId, add: true }));
      const collectionReq: CollectionReqDto = {
        collectionId: collectionId,
      };
      await apiAxios.post(
        '/collection/delete-collection-if-creator',
        collectionReq,
      );
      await dispatch(getCollectionsSimpleAsync());
      dispatch(updateCollectionsImageDataAsync());
      dispatch(updateProcessingCollections({ collectionId, add: false }));
    } catch (err: unknown) {
      const message = (err as Error).message;
      throw rejectWithValue(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };

      if (state.getSessionStatus === EReducerState.LOADING) {
        // console.log('deleteCollectionAsync: already loading');
        return false;
      }
      if (state.getSessionStatus === EReducerState.FAILED) {
        // console.log(
        //   'deleteCollectionAsync: can not submit, session failed to load',
        // );
        return false;
      }
    },
  },
);

export const updateCollectionsImageDataAsync = createAsyncThunk(
  'generateImages/updateCollectionsImageDataAsync',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      let updatedCollections: GenerateCollectionSimpleDto[] = [];

      const getImageDataAsync = state.createdCollections!.map(
        async (collectionData) => {
          if (!collectionData.crestContent.contentUrl) {
            return null;
          }
          if (collectionData.imageData) {
            const collectionDto: GenerateCollectionSimpleDto = {
              collectionId: collectionData.collectionId,
              name: collectionData.name,
              crestContentId: collectionData.crestContentId,
              crestContent: collectionData.crestContent,
              imageData: collectionData.imageData,
              partyVersion: collectionData.partyVersion,
              type: collectionData.type,
            };
            updatedCollections.push(collectionDto);
            return null;
          }
          const response = await axios.get(
            collectionData.crestContent.contentUrl,
            {
              responseType: 'blob',
            },
          );
          const blob = new Blob([response.data], { type: 'image/png' });
          const dataUrl: string = URL.createObjectURL(blob);
          const collectionDto: GenerateCollectionSimpleDto = {
            collectionId: collectionData.collectionId,
            name: collectionData.name,
            crestContentId: collectionData.crestContentId,
            crestContent: collectionData.crestContent,
            imageData: dataUrl,
            partyVersion: collectionData.partyVersion,
            type: collectionData.type,
          };
          updatedCollections.push(collectionDto);
          return;
        },
      );
      await Promise.all(getImageDataAsync);
      dispatch(setCreatedCollections(updatedCollections));
    } catch (err: unknown) {
      const message = (err as Error).message;
      throw rejectWithValue(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };

      if (state.getSessionStatus === EReducerState.FAILED) {
        // console.log(
        //   'updateCollectionsImageDataAsync: can not submit, session failed to load',
        // );
        return false;
      }
      if (state.createdCollectionsImageDataState === EReducerState.LOADING) {
        // console.log('updateCollectionsImageDataAsync: already loading');
        return false;
      }
      if (state.createdCollections === null) {
        // console.log(
        //   'updateCollectionsImageDataAsync: createdCollections is null',
        // );
        return false;
      }
    },
  },
);

export const changeSessionCollectionAsync = createAsyncThunk(
  'generateImages/changeSessionCollectionAsync',
  async (
    { collectionId, callback }: { collectionId: string; callback?: () => void },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      await apiAxios.post('/session/generate/change-active-collection', {
        collectionId: collectionId,
      });
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      if (state.sessionState === EGenerateSessionState.BLANK_SLATE) {
        dispatch(setSessionState(EGenerateSessionState.ACTIVE));
      }
      dispatch(resetGenerateState());
      await dispatch(syncGenerateSessionAsync());

      if (callback) {
        callback();
      }
    } catch (err: unknown) {
      const message = (err as Error).message;
      throw rejectWithValue(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };

      if (state.getSessionStatus === EReducerState.LOADING) {
        // console.log('changeSessionCollectionAsync: already loading');
        return false;
      }
      if (state.getSessionStatus === EReducerState.FAILED) {
        // console.log(
        //   'changeSessionCollectionAsync: can not submit, session failed to load',
        // );
        return false;
      }
    },
  },
);

export const removeExplorerCollectionAsync = createAsyncThunk(
  'generateImages/removeExplorerCollectionAsync',
  async (collectionId: string, { dispatch, getState, rejectWithValue }) => {
    try {
      //TODO
      throw rejectWithValue('not implemented');
    } catch (err: unknown) {
      const message = (err as Error).message;
      throw rejectWithValue(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };

      if (state.getSessionStatus === EReducerState.LOADING) {
        // console.log('removeExplorerCollectionAsync: already loading');
        return false;
      }
      if (state.getSessionStatus === EReducerState.FAILED) {
        // console.log(
        //   'removeExplorerCollectionAsync: can not submit, session failed to load',
        // );
        return false;
      }
    },
  },
);

export const updateGridStateAsync = createAsyncThunk(
  'generateImages/updateGridStateAsync',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(updateGridState());
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      const gridState: string[][] = state.gridState;
      const serializedGridState: string = JSON.stringify(gridState);
      const updateDto: GenerateUpdateGridStateDto = {
        serializedGridState: serializedGridState,
      };
      await apiAxios.post('/session/generate/update-grid-state', updateDto);
    } catch (err: unknown) {
      const message = (err as Error).message;
      throw rejectWithValue(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      if (state.pageLoadState === EReducerState.LOADING) {
        // console.log(
        //   'updateGridStateAsync: can not submit, still loading session',
        // );
        return false;
      }
      if (state.getSessionStatus === EReducerState.FAILED) {
        // console.log(
        //   'updateGridStateAsync: can not submit, session failed to load',
        // );
        return false;
      }
    },
  },
);

export const updateInstrumentStateAsync = createAsyncThunk(
  'generateImages/updateInstrumentStateAsync',
  async (
    instrument: GenerateInstrumentDto,
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      dispatch(setInstrument(instrument));

      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };

      if (
        !state.collectionIdentifier.id ||
        !state.collectionIdentifier.name ||
        !state.instrument
      ) {
        throw rejectWithValue(
          'updateInstrumentStateAsync: collectionIdentifier or instrument is undefined',
        );
      }

      const instrumentDto: GenerateCreateInstrumentDto = {
        name: state.collectionIdentifier.name,
        instrument: state.instrument,
        collectionId: state.collectionIdentifier.id,
      };
      await apiAxios.post('/session/generate/update-instrument', instrumentDto);
    } catch (err: unknown) {
      const message = (err as Error).message;
      throw rejectWithValue(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      if (state.pageLoadState === EReducerState.LOADING) {
        // console.log(
        //   'updateInstrumentAsync: can not submit, still loading session',
        // );
        return false;
      }
      if (state.getSessionStatus === EReducerState.FAILED) {
        // console.log(
        //   'updateInstrumentAsync: can not submit, session failed to load',
        // );
        return false;
      }
    },
  },
);

export const removeImageAsync = createAsyncThunk(
  'generateImages/removeImageAsync',
  async (
    {
      dList,
      image,
    }: { dList: DLinkList<DisplayImageContent>; image: DisplayImageContent },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      dispatch(removeImage({ dList: dList, image: image }));
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      const serializedGridState: string = JSON.stringify(state.gridState);
      const removeDto: GenerateRemoveSessionImageDto = {
        contentId: image.content.id,
        serializedGridState: serializedGridState,
      };
      await apiAxios.post('/session/generate/remove-image', removeDto);
      await dispatch(updateGridStateAsync());
    } catch (err: unknown) {
      const message = (err as Error).message;
      throw rejectWithValue(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      if (state.pageLoadState === EReducerState.LOADING) {
        // console.log('removeImagesAsync: can not submit, still loading session');
        return false;
      }
      if (state.getSessionStatus === EReducerState.FAILED) {
        // console.log(
        //   'removeImagesAsync: can not submit, session failed to load',
        // );
        return false;
      }
    },
  },
);

export const moveImageAsync = createAsyncThunk(
  'generateImages/moveImagAsync',
  async (
    moveWithIdDto: MoveWithContentIdDto,
    { dispatch, getState, rejectWithValue },
  ) => {
    const { generateImages: initState } = getState() as {
      generateImages: GenerateImagesState;
    };
    //get list from content Id
    const contentId = moveWithIdDto.contentId;
    let targetList: DLinkList<DisplayImageContent> | null = null;
    let targetImage: DisplayImageContent | null = null;
    let i = 0;
    let j = 0;
    for (let list of initState.images.values()) {
      for (let image of list.values()) {
        if (image.content.id === contentId) {
          targetList = list;
          targetImage = image;
          break;
        }
        j++;
      }
      if (targetList !== null) {
        //found our list
        break;
      }
      j = 0;
      i++;
    }

    if (targetList === null || targetImage === null) {
      throw rejectWithValue('moveImageAsync: list or image is null');
    }

    const manipulateDto: ManipulateImageDto = {
      dList: targetList,
      image: targetImage,
      listIndex: i,
      imageIndex: j,
      moveDirection: moveWithIdDto.moveDirection,
    };

    switch (manipulateDto.moveDirection) {
      case EMoveGridDirection.LEFT:
        dispatch(moveImageLeft(manipulateDto));
        break;
      case EMoveGridDirection.RIGHT:
        dispatch(moveImageRight(manipulateDto));
        break;
      case EMoveGridDirection.UP:
        dispatch(moveImageUp(manipulateDto));
        break;
      case EMoveGridDirection.DOWN:
        dispatch(moveImageDown(manipulateDto));
        break;
      default:
        throw rejectWithValue(
          'moveImageAsync: invalid move direction: ' +
            manipulateDto.moveDirection,
        );
    }
    dispatch(updateGridStateAsync());
    const { generateImages: state } = getState() as {
      generateImages: GenerateImagesState;
    };
    let point: GridPoint | null = null;
    let found = false;
    for (let i = 0; i < state.gridState.length; i++) {
      for (let j = 0; j < state.gridState[i].length; j++) {
        if (state.gridState[i][j] === manipulateDto.image.content.id) {
          manipulateDto.image.colNum = j + 1;
          point = {
            x: i,
            y: j,
          };
          found = true;
          break;
        }
      }
      if (found) {
        break;
      }
    }
    if (!point) {
      throw rejectWithValue('moveImageAsync: point is undefined');
    }
    return point;
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      if (state.pageLoadState === EReducerState.LOADING) {
        // console.log('moveImageAsync: can not submit, still loading session');
        return false;
      }
      if (state.getSessionStatus === EReducerState.FAILED) {
        // console.log('moveImageAsync: can not submit, session failed to load');
        return false;
      }
    },
  },
);

export const addImagesToCollectionAsync = createAsyncThunk(
  'generateImages/addImagesToCollectionAsync',
  async (dto: AddToCollectionRequest, { dispatch, rejectWithValue }) => {
    try {
      dispatch(
        updateProcessingCollections({
          collectionId: dto.collectionId,
          add: true,
        }),
      );

      await apiAxios.post('/collection/add-to-collection', dto);

      dispatch(
        updateProcessingCollections({
          collectionId: dto.collectionId,
          add: false,
        }),
      );
    } catch (err: unknown) {
      const message = (err as Error).message;
      throw rejectWithValue(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };

      if (state.getSessionStatus === EReducerState.FAILED) {
        // console.log(
        //   'addImagesToCollectionAsync: can not submit, session failed to load',
        // );
        return false;
      }
    },
  },
);

export const submitGeneratedImageAsync = createAsyncThunk(
  'generateImages/submitGeneratedImageAsync',
  async (
    {
      dList,
      image,
    }: { dList: DLinkList<DisplayImageContent>; image: DisplayImageContent },
    { dispatch, rejectWithValue },
  ) => {
    try {
      dispatch(validateContentNft({ dList: dList, image: image }));
      const updateState: UpdateContentNftState = {
        contentId: image.content.id,
        state: ENftState.MINT_REQUESTED,
      };
      dispatch(
        updateContentNftState({
          dList: dList,
          image: image,
          updateNft: updateState,
        }),
      );
      const submitDto: SubmitNftDto = {
        contentId: image.content.id,
        network: getNetworkType(),
      };
      const response = await apiAxios.post('/nft/submit-nft', submitDto);
      const returnNft: ReturnSubmitNftDto = response.data as ReturnSubmitNftDto;
      const updateNftName: UpdateContentNftName = {
        nftId: returnNft.id,
        contentId: image.content.id,
        name: returnNft.name,
      };
      dispatch(
        updateContentNftName({
          dList: dList,
          image: image,
          updateName: updateNftName,
        }),
      );
      if (returnNft.state === ENftState.WAITING_APPROVAL) {
        const updateState: UpdateContentNftState = {
          contentId: image.content.id,
          state: returnNft.state,
        };
        dispatch(
          updateContentNftState({
            dList: dList,
            image: image,
            updateNft: updateState,
          }),
        );
      }
      return returnNft;
    } catch (err: unknown) {
      const message = (err as Error).message;
      throw rejectWithValue({
        message: message,
        dList: dList,
        image: image,
      });
    }
  },
  {
    condition: (_, { getState }) => {
      const { generateImages: state } = getState() as {
        generateImages: GenerateImagesState;
      };
      if (state.pageLoadState === EReducerState.LOADING) {
        // console.log(
        //   'submitGeneratedImageAsync: can not submit, still loading session',
        // );
        return false;
      }
      if (state.getSessionStatus === EReducerState.FAILED) {
        // console.log(
        //   'submitGeneratedImageAsync: can not submit, session failed to load',
        // );
        return false;
      }
    },
  },
);

export const generateImagesReducer = generateImagesSlice.reducer;
