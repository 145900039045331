import { Fragment } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  addImagesToCollectionAsync,
  changeSessionCollectionAsync,
  selectProcessingCollections,
  updateProcessingCollections,
} from '../features/generate/generate-images-slice';
import {
  DisplayImageContent,
  AddToCollectionRequest,
  GenerateCollectionIdentifierDto,
} from '../features/generate/types';
import {
  Collection,
  createCollection,
  deleteCollection,
} from '../utils/collections';
import { get, isEmpty } from 'lodash';
import { ECollectionType } from '../app/enum';
import { Spinner } from 'flowbite-react';
import { ECollectionModalType } from '../features/generate/enum';
import { BookOpenIcon, TrashIcon } from '@heroicons/react/20/solid';

interface OpenCollectionButtonProps {
  collectionId: string;
  collectionType: string;
  onClearSelection: () => void;
  onClose: () => void;
  buttonClass?: string;
}

export const OpenCollectionButton = ({
  collectionId,
  collectionType,
  onClearSelection,
  onClose,
  buttonClass = 'h-6 w-6',
}: OpenCollectionButtonProps) => {
  const dispatch = useAppDispatch();
  return (
    <button
      type="button"
      className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-green-400 shadow-sm hover:bg-slate-700"
      onClick={() => {
        if (!collectionType) {
          console.error('Select Collection collection type is undefined');
        }
        if (collectionType === ECollectionType.PARTY) {
          window.open(`/party/${collectionId}`, '_blank');
        } else {
          dispatch(changeSessionCollectionAsync({ collectionId }));
          if (onClearSelection) {
            onClearSelection();
          }
        }
        onClose();
      }}
    >
      <BookOpenIcon className={buttonClass} />
    </button>
  );
};

interface CollectionCardProps {
  collection: Collection;
  modalType: ECollectionModalType;
  currentCollection: GenerateCollectionIdentifierDto;
  clearSelectionCallback?: () => void;
  closeModal: () => void;
  imageForCreate?: DisplayImageContent;
  additionalImageIds?: string[];
  allSelectedImageIds?: string[];
  onCreateCollection: (collectionName: string) => void;
  showCollectionType: ECollectionType;
}

export const CollectionCard = ({
  collection,
  modalType,
  currentCollection,
  clearSelectionCallback,
  closeModal,
  imageForCreate = undefined,
  additionalImageIds = undefined,
  onCreateCollection,
  allSelectedImageIds,
  showCollectionType,
}: CollectionCardProps) => {
  const dispatch = useAppDispatch();
  const processingCollections = useAppSelector(selectProcessingCollections);
  const isProcessing =
    processingCollections.includes(collection.collectionId) ||
    (imageForCreate && processingCollections.includes('create'));

  const queryClient = useQueryClient();
  const deleteCollectionMutation = useMutation({
    mutationFn: deleteCollection,
    onMutate: (collectionId: string) => {
      dispatch(
        updateProcessingCollections({
          collectionId: collectionId,
          add: true,
        }),
      );
    },
    onSuccess: (collectionId: string) => {
      dispatch(
        updateProcessingCollections({
          collectionId: collectionId,
          add: false,
        }),
      );
      queryClient.invalidateQueries({ queryKey: ['collections'] });
    },
  });

  const createCollectionMutation = useMutation({
    mutationFn: createCollection,
    onMutate: () => {
      dispatch(
        updateProcessingCollections({
          collectionId: 'create',
          add: true,
        }),
      );
    },
    onSuccess: async ({ collectionId }) => {
      if (!isEmpty(additionalImageIds)) {
        const addImagesDto: AddToCollectionRequest = {
          collectionId: collectionId,
          contentIds: additionalImageIds,
          type: ECollectionType.EXPLORER,
        };
        await dispatch(addImagesToCollectionAsync(addImagesDto));
      }

      dispatch(
        updateProcessingCollections({
          collectionId: 'create',
          add: false,
        }),
      );
      onCreateCollection(collectionId);
      queryClient.invalidateQueries({ queryKey: ['collections'] });
    },
  });

  const crestContent = get(collection, 'crestContent');
  const contentUrl = get(crestContent, 'contentUrl');
  const thumbnailUrl = get(crestContent, 'thumbnailFullUrl');
  const imageUrl = thumbnailUrl || contentUrl;

  return (
    <Fragment>
      {showCollectionType === collection.type &&
        collection.collectionId !== currentCollection.id && (
          <div className="flex justify-center items-center bg-slate-600 p-3 rounded-lg">
            <div className="flex flex-col items-center justify-center">
              {imageUrl && (
                <div className="flex-shrink-0 object-cover object-center">
                  <img
                    src={imageUrl}
                    alt={collection.name}
                    className="h-full w-full ounded-md object-cover object-center"
                  />
                </div>
              )}
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-base font-semibold pb-2 leading-6 text-white break-words">
                  {collection.name}
                </h3>
                {!imageForCreate && (
                  <h4 className="text-sm font-normal text-gray-400">
                    {`Last modified: ${new Date(
                      collection.lastModifiedAt,
                    ).toDateString()}`}
                  </h4>
                )}
                {isProcessing && <Spinner />}
                {!isProcessing && (
                  <>
                    {modalType === ECollectionModalType.CHANGE_COLLECTION && (
                      <div className="mt-5">
                        <OpenCollectionButton
                          collectionId={collection.collectionId}
                          collectionType={collection.type}
                          onClearSelection={clearSelectionCallback}
                          onClose={closeModal}
                          buttonClass="h-10 w-10"
                        />
                        {showCollectionType === ECollectionType.EXPLORER && (
                          <button
                            type="button"
                            className="ml-3 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-red-400 shadow-sm hover:bg-slate-700"
                            onClick={() =>
                              deleteCollectionMutation.mutate(
                                collection.collectionId,
                              )
                            }
                          >
                            <TrashIcon className="h-10 w-10" />
                          </button>
                        )}
                      </div>
                    )}
                    {modalType === ECollectionModalType.ADD_TO_COLLECTION && (
                      <button
                        type="button"
                        className="ml-3 mt-3 inline-flex items-center rounded-md bg-neutral-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        onClick={() => {
                          if (imageForCreate) {
                            // additional images get added in the createCollectionMutation.onSuccess
                            createCollectionMutation.mutate({
                              image: imageForCreate,
                              type: collection.type,
                            });
                          } else {
                            const addRequest: AddToCollectionRequest = {
                              collectionId: collection.collectionId,
                              contentIds: allSelectedImageIds,
                              type: collection.type,
                            };

                            dispatch(addImagesToCollectionAsync(addRequest));
                          }
                        }}
                      >
                        {imageForCreate ? 'CREATE' : 'ADD'}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
    </Fragment>
  );
};
