import { sortBy } from 'lodash';
import { apiAxios } from '../app/axios';
import { DisplayImageContent } from '../features/generate/types';
import { ECollectionType } from '../app/enum';

// Subject: explanation of what's happening in the image.
// Style: artistic style of the image. For example impressionist,
// surrealist, pop art, etc.
// Resolution: how sharp and detailed the image is.
// Additional details: the vibe of the image. Examples: sci-fi and
// dystopia
// Colour: as a tone or in objects. for example iridescent gold.
// Lighting: studio lighting, golden hour, etc.
// Reference: if there's a visual reference. For example, Picasso

export const visualDescriptionPlaceholder =
  'Most Complex Part of the Image: \n\nSimple Details: \n\nBackground: \n\nKeywords for mood, colours, and composition: \n\nReferences (optional):';

export interface ConsultationAnswer {
  id?: string;
  contentId: string;
  visualDescription: string;
}
export interface Consultation {
  id?: string;
  collectionId: string;
  answers: ConsultationAnswer[];
}

export interface ConsultationResponse {
  id: string;
  collectionId: string;
  answers: ConsultationAnswer[];
}

export interface Collection {
  collectionId: string;
  createdAt?: Date;
  crestContent: any;
  crestContentId: string;
  lastModifiedAt?: Date;
  name: string;
  partyVersion?: number;
  type: ECollectionType;
}

export enum CollectionSort {
  NAME_ASC,
  NAME_DESC,
  LAST_MODIFIED,
  LAST_CREATED,
}

export enum CollectionRole {
  EDITOR = 'EDITOR',
  ADMIN = 'ADMIN',
}

export const getCollectionRoleOptions = () => {
  return [
    { value: CollectionRole.EDITOR, label: 'Editor' },
    { value: CollectionRole.ADMIN, label: 'Admin' },
  ];
};

export const getSortedCollections = (
  collections: Collection[],
  sortSelection: CollectionSort,
): Collection[] => {
  switch (sortSelection) {
    case CollectionSort.NAME_ASC:
      return sortBy(collections, ['name']);
    case CollectionSort.NAME_DESC:
      return sortBy(collections, ['name']).reverse();
    case CollectionSort.LAST_MODIFIED:
      return sortBy(collections, ['lastModifiedAt']).reverse();
    case CollectionSort.LAST_CREATED:
      return sortBy(collections, ['createdAt']).reverse();
    default:
      return collections;
  }
};

export const getCollection = (collectionId: string): Promise<any> =>
  apiAxios
    .post('/collection/get-collection-content', { collectionId })
    .then((response) => response.data);

export const fetchCollections = (): Promise<any> =>
  apiAxios
    .post('/collection/get-collections-explorer-simple')
    .then((response) => response.data);

export const fetchRemovedContent = (
  collectionId: string | undefined,
): Promise<any> =>
  apiAxios
    .post('/collection/get-collection-removed', { collectionId })
    .then((response) => response.data);

export const createCollection = ({
  image,
  contentIds,
  type,
}: {
  image: DisplayImageContent;
  contentIds: string[];
  type: ECollectionType;
}): Promise<Collection> =>
  apiAxios
    .post('/collection/create-collection-generate-name', {
      crestContentId: image.content.id,
      prompt: image.content.prompt,
      contentIds,
      type,
    })
    .then((response) => response.data);

export const deleteCollection = (collectionId: string): Promise<void> =>
  apiAxios
    .post('/collection/delete-collection-if-creator', { collectionId })
    .then((response) => response.data);

export const fetchCollectionRoles = (collectionId: string): Promise<any> =>
  apiAxios
    .post('/collection/get-collection-roles', {
      collectionId,
    })
    .then((response) => response.data);

export const addContentToCollection = ({
  collectionId,
  contentId,
}: {
  collectionId: string | undefined;
  contentId: string;
}): Promise<void> =>
  apiAxios
    .post('/collection/add-to-collection', {
      collectionId,
      contentIds: [contentId],
      type: ECollectionType.EXPLORER,
    })
    .then((response) => response.data);

export const addUserToCollection = ({
  collectionId,
  username,
  role,
}: {
  collectionId: string;
  username: string;
  role: CollectionRole;
}): Promise<void> =>
  apiAxios
    .post('/collection/add-collection-role', {
      collectionId,
      signIn: username,
      role,
    })
    .then((response) => response.data);

export const getConsultation = ({
  collectionId,
  username,
}: {
  collectionId: string;
  username: string | undefined;
}): Promise<ConsultationResponse> =>
  apiAxios
    .get('/consultation/get', { params: { ...{ collectionId }, username } })
    .then((response) => response.data);

export const submitConsultation = (
  consultation: Consultation,
): Promise<ConsultationResponse> =>
  apiAxios
    .post('/consultation/create', consultation)
    .then((response) => response.data);

export const updateConsultation = (
  consultation: Consultation,
): Promise<ConsultationResponse> =>
  apiAxios
    .post('/consultation/update', {
      consultationId: consultation.id,
      answers: consultation.answers,
    })
    .then((response) => response.data);
